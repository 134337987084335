<nav class="header-container">
  <div *ngIf="currentUrl !== 'login'" class="header-buttons d-block d-sm-none">
    <a (click)="goBack()"> <img class="return" src="./assets/images/arrow-left-black.svg" /></a>
  </div>
  <div class="header-logos">
    <span class="d-none d-sm-block" appTagger [event_category]="category" event_label="logo – santander">
      <img src="./assets/images/Banco_Santander_Logotipo.svg" alt="logo" />
    </span>
    <span class="d-block d-sm-none" appTagger [event_category]="category" event_label="logo – santander">
      <img class="icon-logo" src="./assets/images/logo-santander-red.svg" alt="logo" />
    </span>
  </div>
  <div class="header-buttons">
    <a [routerLink]="['/not/FAQ/login']" class="help" *ngIf="router && router.url !== '/not/FAQ/login'" appTagger
      [event_category]="category" event_label="{{ 'HEADER-HELP' | translate }}">{{ 'HEADER-HELP' | translate }}</a>
    <a rel="noopener noreferrer" class="d-none d-sm-inline-block" *ngIf="router && router.url === '/login'"
      (click)="redirectIdentification()" appTagger [event_category]="category"
      event_label="{{ 'HEADER-REGISTER' | translate }}">
      <button>{{ 'HEADER-REGISTER' | translate }}</button>
    </a>
  </div>
</nav>
