<div [ngClass]="zoom">
  <div class="summary-card mb-3" *ngIf="showStoreConditions && currentUrl !== '/proposal/step-confirmation'">
    <div class="summary-title d-flex justify-content-between align-items-center">
      <p class="details">{{ 'PROPOSAL-STORE-CONDITIONS-TITLE' | translate }}</p>
      <img src="./assets/images/ic_file_transfer.svg" class="alternate-img"
        (click)="toggleStoreConditionsVisibility()" />
    </div>

    <div class="sidebar-box position-relative">
      <!-- Modal quanto vou receber -->
      <div class="alert-sidebar white" *ngIf="whiteModal">
        <header class="d-flex justify-content-between mb-4">
          <img src="./assets/images/ic_percent.png" />
          <mat-icon aria-hidden="false" (click)="whiteModal = !whiteModal">close</mat-icon>
        </header>
        <p>
          Como solicitou entrada, você teve um benefício na retenção, economizando
          <strong>{{ proposal?.shopkeeperData?.withheldAmount | localeCurrency }}</strong>
        </p>
        <p>
          Percentual e valor de retenção sobre o valor <strong>liberado</strong>
          <span class="percentual-liberado">{{
            proposal?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage
            }}</span>
          {{ proposal?.shopkeeperData?.releasedAmount | localeCurrency }}
        </p>
        <p>
          Percentual e valor de retenção sobre o valor da <strong>compra</strong><br />
          <span class="percentual-compra">{{
            proposal?.shopkeeperData?.retentionPercentageByPurchaseValue | localePercentage
            }}</span>
          <sup>{{ proposal?.shopkeeperData?.retentionPercentageByReleasedAmount | localePercentage }}</sup><br />
          {{ proposal?.shopkeeperData?.releasedAmountPlusEntry | localeCurrency }}
        </p>
      </div>
      <!--/ Fim Modal quanto vou receber -->

      <div class="sidebar-div">
        <div class="row">
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'PURCHASE-AMOUNT-TITLE' | translate }}</p>
            <p class="data-value">{{ proposal?.purchaseValue | localeCurrency }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'Entry Price' | translate }}</p>
            <p class="data-value">{{ proposal?.entryValue | localeCurrency }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label" *ngIf="proposal?.proposalData?.product === productCode.CDC">
              {{ 'FINANCED_AMOUNT' | translate }}
            </p>
            <p class="data-label" *ngIf="proposal?.proposalData?.product === productCode.CSC">
              {{ 'PROPOSAL-SUMARY-VALUE-GIVEN-CSC' | translate }}
            </p>
            <p class="data-label" *ngIf="proposal?.proposalData?.product === productCode.CSCP">
              {{ 'PROPOSAL-SUMARY-VALUE-GIVEN-CSCP' | translate }}
            </p>
            <p class="data-value bold text-lg">
              {{ proposal?.financedValue | localeCurrency }}
            </p>
          </div>
          <div class="col-12 d-flex flex-row pointer" (click)="whiteModal = !whiteModal"
            *ngIf="proposal?.shopkeeperData?.showReleasedAmount">
            <mat-icon class="info-icon icon-v-inverse mr-1">error_outline</mat-icon>
            <p class="">{{ 'How Much Will I Receive?' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="financing-box" *ngIf="!showStoreConditions">
    <span class="d-none d-sm-block">
      <span class="d-none d-sm-block">
        <img src="./assets/images/logo-santander_bkp.png" alt="" />
      </span>
    </span>

    <p class="financing-text">{{ 'STEP-REGISTER-SIDEBAR-TEXT' | translate }}</p>

    <p class="alternate-img" (click)="toggleStoreConditionsVisibility()">
      <img src="./assets/images/ic_file_transfer.svg" class="mr-2" />
      {{ 'STEP-REGISTER-SIDEBAR-BACK-LINK' | translate }}
    </p>
  </div>

  <div class="summary-card" *ngIf="showStoreConditions">
    <div class="summary-title">
      <p class="details" *ngIf="currentUrl !== '/proposal/step-confirmation'">
        {{ 'PROPOSAL-STORE-SUMMARY' | translate }}
      </p>
      <p class="details" *ngIf="currentUrl === '/proposal/step-confirmation'">
        {{ 'PROPOSAL-SUMARY-RESUME' | translate }}
      </p>
    </div>

    <div class="sidebar-box" *ngIf="proposal?.proposalData?.client === personsType.PF">
      <div class="sidebar-div">
        <div class="row">
          <div class="col-12 mb-3" *ngIf="currentUrl === '/proposal/step-confirmation'">
            <p class="data-label">{{ 'STEP-CONFIRMATION-FULLNAME' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.fullName }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'STEP-CONFIRMATION-DOCUMENT-NUMBER' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.documentNumber | mask: 'AAA.AAA.AAA-AA' }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'STEP-CONFIRMATION-BIRTH-DATE' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.birthDate | date: 'dd/MM/yyyy' }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'STEP-CONFIRMATION-PHONE' | translate }}</p>
            <p class="data-value">
              ({{ proposal?.personalData?.phone?.dddNumber }})
              {{ proposal?.personalData?.phone?.number | mask: 'AAAAA-AAAA' }}
            </p>
          </div>
          <!-- <div class="col-12" *ngIf="currentUrl !== '/proposal/step-confirmation'">
            <p class="data-label">{{ 'STEP-REGISTER-EMAIL' | translate }}</p>
            <p class="data-value">
              {{ proposal?.personalData?.emailCode }}
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <div class="sidebar-box" *ngIf="proposal?.proposalData?.client === personsType.PJ">
      <div class="sidebar-div">
        <div class="row">
          <div class="col-12 mb-3" *ngIf="currentUrl === '/proposal/step-confirmation'">
            <p class="data-label">{{ 'STEP-REGISTER-COMPANY-NAME' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.fullName }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'STEP-REGISTER-COMPANY-DOCUMENT-NUMBER' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.documentNumber | mask: 'AA.AAA.AAA/AAAA-AA' }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'DATE-OF-FOUNDATION' | translate }}</p>
            <p class="data-value">{{ proposal?.personalData?.birthDate | date: 'dd/MM/yyyy' }}</p>
          </div>
          <div class="col-12 mb-3">
            <p class="data-label">{{ 'STEP-REGISTER-MAIN-PHONE' | translate }}</p>
            <p class="data-value">
              ({{ proposal?.personalData?.phone?.dddNumber }})
              {{ proposal?.personalData?.phone?.number | mask: 'AAAAA-AAAA' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
