import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface EcmDownload {
  fileContent?: string;
  mimeType?: string;
  name?: string;
  blob?: Blob;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private http: HttpClient) {}

  /**
   * If using Internet Explorer uses msSaveBlob method
   */
  private saveBlobIE(blob: Blob, filename: string): boolean {
    return (window.navigator as any).msSaveBlob(blob, filename);
  }

  /**
   * Creates a link to force the file download
   */
  private saveBlobDefault(blob: Blob, filename: string): boolean {
    const blobURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = blobURL;
    anchor.click();
    return true;
  }

  /**
   * Verify if Internet Explorer is the current browser
   */
  private isIE(): boolean {
    return /MSIE|Trident/.test(window.navigator.userAgent);
  }

  /**
   * Saves blob data as a file, triggering browser download
   */
  saveBlob(blob: Blob, filename: string) {
    if (this.isIE()) return this.saveBlobIE(blob, filename);
    return this.saveBlobDefault(blob, filename);
  }

  /**
   * Loads data from url, creates a blob and downloads it
   */
  downloadFile(url: string, filename = 'file', type = '', headers = new HttpHeaders()): Observable<boolean> {
    return this.http.get(url, { headers, responseType: 'blob' }).pipe(
      map(res => {
        const blob = new Blob([res], { type });
        return this.saveBlob(blob, filename);
      })
    );
  }

  /**
   * Download shortcut for PDF files (enforces "application/pdf" mime-type)
   */
  downloadPDF(url: string, filename = 'file.pdf', headers = new HttpHeaders()): Observable<boolean> {
    return this.downloadFile(url, filename, 'application/pdf', headers);
  }

  base64toBlob(data: string, contentType?: string): Blob {
    if (!data) return;

    let byteCharacters: string;
    let byteNumbers: any[];
    let byteArray: Uint8Array;

    contentType = contentType || '';
    byteCharacters = atob(data);
    byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: contentType });
  }

  fileToBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  }

  downloadThumbnailECM(imageUuid: string): Observable<any> {
    const headers = new HttpHeaders();

    return this.http.get(`${environment.apiRootUrl}/ecm/1.0.0/ecm/thumbnail/${imageUuid}`, {
      responseType: 'blob',
      headers
    });
  }

  downloadFileECM(url: string, headers = new HttpHeaders()): Observable<EcmDownload> {
    return new Observable(observer => {
      this.http.get<EcmDownload>(url, { headers, responseType: 'json' }).subscribe(data => {
        const blob = this.base64toBlob(data.fileContent, data.mimeType);
        observer.next({ blob: blob, name: data.name, mimeType: data.mimeType });
      });
    });
  }

  sendCavasSignature(canvas, sign, uuidDocumento, certify) {
    const body = {
      documento: {
        tipoDocumento: '23400',
        descricaoDocumento: 'E-SIGN_PRESENTATION'
      },
      signIMG: canvas,
      signer: sign,
      certify: certify
    };

    return this.http.post<any>(`${environment.apiRootUrl}/esign/sign/signDocRest/` + uuidDocumento, body);
  }
}
