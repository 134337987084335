import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as fromLogin from '@app/core/state/login';
import { ClearUserDataAction } from '@app/signup/state/signup.actions';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ChallengeService } from '@shared/services/challenge/challenge.service';
import { of } from 'rxjs';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { FType } from '../login/login.reducer';
import { selectFtype } from '../login/login.selectors';
import * as actions from './challenge.actions';

@Injectable()
export class ChallengeEffects {
  constructor(
    private actions$: Actions,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private challengeService: ChallengeService,
    private translateService: TranslateService,
    private gentagger: GenTagger,
    private store$: Store<AppState>
  ) {}

  @Effect()
  startChallengeEffect = this.actions$.pipe(
    ofType<actions.StartChallenge>(actions.ChallengeActionTypes.START_CHALLENGE),
    switchMap(action => {
      return this.challengeService.startChallenge(action.payload).pipe(
        switchMap(res => {
          if (res.validation) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.id = 'modal-component';
            dialogConfig.width = '600px';
            dialogConfig.height = '350px';
            this.challengeService.showRegistrationUnavailableModal({}, dialogConfig);
            this.challengeService.confirmed();
            return [new actions.StartChallengeSuccess(res)];
          }

          const redirectPattern = this.getRedirectPattern('', res.challengeType);

          if (redirectPattern) {
            return [new actions.StartChallengeSuccess(res), ...redirectPattern];
          }

          return [new actions.StartChallengeSuccess(res)];
        }),
        catchError((err: any) => {
          let _title = '';
          let _description = '';
          let _category = '';

          if (err.status === 501 || err.status === 502 || err.status === 503 || err.status === 504) {
            _title = null;
            _description = 'Não foi possível validar esse CPF no momento.';
            _category = '​/portallojista/primeiroacesso';
          } else if (this.router.url.includes('recoveryType=primary-access')) {
            _title = 'Documento inválido';
            _description = 'Não foi possível validar esse CPF no momento. Entre em contato com o gestor da loja.';
            _category = '​/portallojista/primeiroacesso';
          } else {
            _title = 'Erro ao recuperar a senha';
            _description = 'Não conseguimos recuperar sua senha no momento por motivos de segurança.';
            _category = '​/portallojista/login​​';
          }
          const modalAction: ExtendedTag = {
            event_action_type: Tag.AbriuModal,
            event_action_complement: _title
          };
          this.gentagger.setTag({
            event_action: modalAction,
            event_category: _category,
            event_label: _description
          });

          this.GenerateModalDialog(_title, _description);
          return [new actions.ErrorChallenge(err.error)];
        })
      );
    })
  );

  @Effect()
  getChallengeEffect = this.actions$.pipe(
    ofType<actions.GetChallenge>(actions.ChallengeActionTypes.GET_CHALLENGE),
    switchMap(action =>
      this.challengeService.getChallenge(action.payload).pipe(
        switchMap(res => {
          this.store$.dispatch(new actions.SetChallengeStatus(null));
          return [new actions.GetChallengeSuccess(res)];
        }),
        catchError((err: any) => {
          return [new actions.ErrorChallenge(err.error)];
        })
      )
    )
  );

  @Effect()
  answerChallengeEffect = this.actions$.pipe(
    ofType<actions.AnswerChallenge>(actions.ChallengeActionTypes.ANSWER_CHALLENGE),
    switchMap((action: any) => {
      const payload = {
        ...this.challengeService.getMainChallengeVariables(),
        payload: null
      };
      payload.payload = action.payload;
      return this.challengeService.answerChallenge(payload);
    }),
    switchMap(res => {
      this.store$.dispatch(new actions.SetChallengeStatus(res.status));
      const { fType, challengeType, isCrossedFlux } = this.challengeService.getMainChallengeVariables();
      if ((fType === 'sign-up' || fType === 'password-recovery') && challengeType === 'BIOMETRY') {
        if (res.status === 'RETRY') {
          return [new actions.ShowRetryModalBiometryChallenge()];
        }
        if (res.status === 'REPROVED') {
          return [new actions.ShowErrorRetryBiometryChallenge()];
        }
        if (isCrossedFlux) {
          if (res.status === 'APPROVED') {
            return [new actions.SetCrossedFluxBiometryStatusAction(true)];
          }
          if (res.status === 'REPROVED') {
            return [new actions.SetCrossedFluxBiometryStatusAction(false)];
          }
        }
      }

      if ((fType === 'sign-up' || fType === 'password-recovery') && challengeType === 'PASSWORD') {
        if (res.status === 'APPROVED') {
          this.showPasswordSuccessModal(fType);
        }
        if (fType === 'password-recovery' && res.status === 'RETRY') {
          this.showPasswordRetryModal();
        }
        if (res.status === 'REPROVED') {
          this.showReprovedPasswordModal();
        }
        return [];
      }

      const redirectPattern = this.getRedirectPattern(res.status, res.nextChallenge || null);

      if (redirectPattern) {
        return [new actions.AnswerChallengeSuccess(res), ...redirectPattern];
      }

      return [new actions.AnswerChallengeSuccess(res)];
    }),
    catchError(err => of(new actions.ErrorChallenge(err)))
  );

  @Effect({ dispatch: false })
  showModalAnswerChallengeError = this.actions$.pipe(
    ofType<actions.AnswerChallengeError>(actions.ChallengeActionTypes.ANSWER_CHALLENGE_ERROR),
    withLatestFrom(this.store$),
    map(([action, state]) => {
      const challengeType = state.challenge.getChallenge.type;
      const modal = this.dialog.getDialogById('modal-error-answer-challenge');
      let _ref = null;

      if (challengeType === 'PASSWORD') {
        _ref = this.dialog.open(PionModalComponent, {
          width: '560px',
          autoFocus: false,
          id: 'modal-error-answer-challenge',
          role: 'dialog',
          data: {
            description: 'Sua senha não pode ser igual às últimas utilizadas.',
            confirmText: 'Tentar novamente',
            type: 'error'
          }
        });
      } else {
        if (!modal) {
          let _category: string;
          const data = {
            description: 'Token inválido, tente novamente.',
            confirmText: 'Tentar novamente',
            type: 'error'
          };
          _ref = this.dialog.open(PionModalComponent, {
            width: '560px',
            autoFocus: false,
            id: 'modal-error-answer-challenge',
            role: 'dialog',
            data
          });
          this.store$
            .select(selectFtype)
            .pipe(first())
            .subscribe(ftype => {
              if (ftype === FType.PASSWORDRECOVERY) {
                _category = '/portallojista/recuperarsenha/token';
              } else if (ftype === FType.SIGNUP) {
                _category = '/portallojista/primeiroacesso/token';
              }
            });
          _ref.afterOpened().subscribe(() => {
            const modalAction: ExtendedTag = {
              event_action_type: Tag.AbriuModal,
              event_action_complement: data.description
            };
            this.gentagger.setTag({
              event_action: modalAction,
              event_category: _category,
              event_label: data.description
            });
          });
          _ref.afterClosed().subscribe(() => {
            const modalAction: ExtendedTag = {
              event_action_type: Tag.Fechou_Modal,
              event_action_complement: data.description
            };
            this.gentagger.setTag({
              event_action: modalAction,
              event_category: _category,
              event_label: data.description
            });
          });
        }
      }
    })
  );

  @Effect({ dispatch: false })
  goToNextChallengeEffect = this.actions$.pipe(
    ofType<actions.GoToNextChallengeAction>(actions.ChallengeActionTypes.GO_TO_NEXT_CHALLENGE),
    switchMap((action: any) => {
      this.getRedirectPattern('', action.payload);
      return [];
    }),
    catchError(err => of(new actions.ErrorChallenge(err)))
  );

  @Effect({ dispatch: false })
  challengeRedirectTokenEffect = this.actions$.pipe(
    ofType<actions.RedirectToken>(actions.ChallengeActionTypes.REDIRECT_TOKEN),
    tap(() => this.router.navigate(['/signup/confirmation-token']))
  );

  @Effect({ dispatch: false })
  challengeRedirectPositiveValidationEffect = this.actions$.pipe(
    ofType<actions.RedirectPositiveValidation>(actions.ChallengeActionTypes.REDIRECT_POSITIVE_VALIDATION),
    tap(() => this.router.navigate(['/signup/challenge']))
  );

  @Effect({ dispatch: false })
  challengeRedirectBiometryEffect = this.actions$.pipe(
    ofType<actions.RedirectBiometry>(actions.ChallengeActionTypes.REDIRECT_BIOMETRY),
    tap(() => this.router.navigate(['/signup/biometry']))
  );

  @Effect({ dispatch: false })
  challengeRedirectTermEffect = this.actions$.pipe(
    ofType<actions.RedirectTerm>(actions.ChallengeActionTypes.REDIRECT_TERM),
    tap(() => this.router.navigate(['/signup/terms']))
  );

  @Effect({ dispatch: false })
  challengeRedirectPasswordEffect = this.actions$.pipe(
    ofType<actions.RedirectPassword>(actions.ChallengeActionTypes.REDIRECT_PASSWORD),
    tap(() => this.router.navigate(['/signup/new-password']))
  );

  @Effect({ dispatch: false })
  challengeRedirectLoginEffect = this.actions$.pipe(
    ofType<actions.RedirectLogin>(actions.ChallengeActionTypes.REDIRECT_LOGIN),
    tap(() => this.router.navigate(['/login']))
  );

  @Effect({ dispatch: false })
  challengeRedirectSignUpDataEffect = this.actions$.pipe(
    ofType<actions.RedirectSignUpData>(actions.ChallengeActionTypes.REDIRECT_SIGN_UP_DATA),
    tap(() => this.router.navigate(['/signup/data']))
  );

  @Effect({ dispatch: false })
  showModalRetryBiometry = this.actions$.pipe(
    ofType<actions.ShowRetryModalBiometryChallenge>(actions.ChallengeActionTypes.SHOW_RETRY_MODAL_BIOMETRY_CHALLENGE),
    tap(() => {
      const modal = this.dialog.getDialogById('modal-error-challenge');
      if (!modal) {
        const ref = this.dialog.open(PionModalComponent, {
          width: '560px',
          autoFocus: false,
          id: 'modal-error-challenge',
          role: 'dialog',
          data: {
            title: this.translateService.instant('MODAL-ERROR-CHALLENGE-BIOMETRY-PHOTO-TITLE'),
            description: this.translateService.instant('MODAL-ERROR-CHALLENGE-BIOMETRY-PHOTO-DESCRIPTION'),
            confirmText: this.translateService.instant('MODAL-ERROR-CHALLENGE-BIOMETRY-PHOTO-CONFIRM'),
            type: 'error'
          }
        });
        ref.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.store$.dispatch(new actions.SetRetryBiometryChallenge(true));
          }
          ref.close();
        });
      }
    })
  );

  @Effect({ dispatch: false })
  showModalErrorRetryBiometry = this.actions$.pipe(
    ofType<actions.ShowErrorRetryBiometryChallenge>(actions.ChallengeActionTypes.SHOW_ERROR_RETRY_BIOMETRY_CHALLENGE),
    tap(() => {
      const modal = this.dialog.getDialogById('modal-error-retry-challenge');
      if (!modal) {
        const ref = this.dialog.open(PionModalComponent, {
          width: '560px',
          autoFocus: false,
          id: 'modal-error-retry-challenge',
          role: 'dialog',
          data: {
            title: this.translateService.instant('MODAL-ERROR-RETRY-CHALLENGE-BIOMETRY-PHOTO-TITLE'),
            description: this.translateService.instant('MODAL-ERROR-RETRY-CHALLENGE-BIOMETRY-PHOTO-DESCRIPTION'),
            confirmText: this.translateService.instant('MODAL-ERROR-RETRY-CHALLENGE-BIOMETRY-PHOTO-CONFIRM'),
            type: 'error'
          }
        });
        ref.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.store$.dispatch(new actions.RedirectLogin());
          }
          ref.close();
        });
      }
    })
  );

  @Effect()
  getCrossedInfo = this.actions$.pipe(
    ofType<actions.GetCrossedFluxInfoAction>(actions.ChallengeActionTypes.GET_CROSSED_FLUX_INFO),
    switchMap(action =>
      this.challengeService.getCrossedFluxInfo(action.payload).pipe(
        switchMap(res => {
          return [new actions.GetCrossedFluxInfoSuccessAction(res)];
        }),
        catchError((err: any) => {
          return [new actions.GetCrossedFluxInfoErrorAction(err.error)];
        })
      )
    )
  );

  @Effect()
  getCrossedLink = this.actions$.pipe(
    ofType<actions.GetCrossedFluxLinkAction>(actions.ChallengeActionTypes.GET_CROSSED_FLUX_LINK),
    switchMap(action =>
      this.challengeService.getCrossedLink().pipe(
        tap(res => {
          console.log(res);
        }),
        switchMap(res => of(new actions.GetCrossedFluxLinkSuccessAction(res.url))),
        catchError(err => of(new actions.GetCrossedFluxLinkErrorAction(err)))
      )
    )
  );

  @Effect()
  checkBiometryStatusAction = this.actions$.pipe(
    ofType<actions.CheckBiometryStatusAction>(actions.ChallengeActionTypes.CHECK_BIOMETRY_STATUS),
    switchMap(action =>
      this.challengeService.checkBiometryStatus(action.payload.uuid, action.payload.delay).pipe(
        switchMap(res => of(new actions.CheckBiometryStatusSuccessAction(res))),
        catchError(err => of(new actions.CheckBiometryStatusErrorAction(err)))
      )
    )
  );

  GenerateModalDialog(title: string, message: string): void {
    this.dialog
      .open(PionModalComponent, {
        data: {
          title: title,
          description: message,
          confirmText: 'OK',
          cancelText: null,
          type: 'alert'
        },
        maxWidth: '500px'
      })
      .afterClosed()
      .subscribe(() => this.router.navigate(['/login']));
  }

  getRedirectPattern(status, nextChallenge) {
    if (status === 'REPROVED' || status === 'REJECTED' || (status === 'APPROVED' && !nextChallenge)) {
      return [new actions.RedirectLogin()];
    } else {
      switch (nextChallenge) {
        case 'EXTERNAL_TOKEN':
          this.router.navigate(['/signup/confirmation-token']);
          break;
        case 'POSITIVE_VALIDATION':
          this.router.navigate(['/signup/challenge']);
          break;
        case 'BIOMETRY':
          this.router.navigate(['/signup/biometry']);
          break;
        case 'TERM':
          this.router.navigate(['/signup/terms']);
          break;
        case 'PASSWORD':
          this.router.navigate(['/signup/new-password']);
          break;
        case 'REGISTER_FORM':
          this.store$.dispatch(new ClearUserDataAction());
          this.router.navigate(['/signup/data']);
          break;
        default:
          break;
      }
    }
  }

  showPasswordRecoverSnackbar() {
    const snackbarConfig: MatSnackBarConfig = {
      duration: 5000
    };

    const snackbarMessage = this.translateService.instant('PASS-RECOVER-RETRY-SNACKBAR');

    this.snackBar.open(snackbarMessage, null, snackbarConfig);
  }

  showPasswordSuccessModal(flow: string, payload = null) {
    if (flow === 'sign-up') {
      this.dialog.open(PionModalComponent, {
        data: {
          title: this.translateService.instant('SIGNUP-SUCCESS-TITLE'),
          description: this.translateService.instant('SIGNUP-SUCCESS-DESCRIPTION'),
          confirmText: this.translateService.instant('ENTER'),
          type: 'success'
        },
        disableClose: true,
        id: 'signup-modal-component',
        width: '520px'
      });
    } else if (flow === 'password-recovery') {
      this.dialog
        .open(PionModalComponent, {
          data: {
            title: this.translateService.instant('PASSWORD-RECOVERY-SUCCESS-TITLE'),
            confirmText: this.translateService.instant('Ok'),
            type: 'success'
          },
          disableClose: true,
          id: 'password-modal-component',
          width: '520px'
        })
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) {
            this.store$.dispatch(new actions.RedirectLogin());
            if (payload) {
              this.store$.dispatch(new fromLogin.actions.SubmitLoginDataAction(payload));
            }
          }
        });
    }
  }

  showReprovedPasswordModal(): void {
    this.dialog
      .open(PionModalComponent, {
        data: {
          title: this.translateService.instant('ERROR-OCCURRED-MODAL-TITLE'),
          description: this.translateService.instant('PASSWORD-RECOVERY-ERROR-DESCRIPTION'),
          confirmText: this.translateService.instant('Ok'),
          type: 'error'
        },
        disableClose: true,
        id: 'password-modal-component',
        width: '100%',
        maxWidth: '520px'
      })
      .afterClosed()
      .subscribe(() => {
        this.store$.dispatch(new actions.RedirectLogin());
      });
  }

  showPasswordRetryModal(): void {
    this.dialog.open(PionModalComponent, {
      data: {
        title: this.translateService.instant('CHOOSE-NEW-PASSWORD'),
        description: this.translateService.instant('CHOOSE-NEW-PASSWORD-SUBTITLE'),
        confirmText: this.translateService.instant('Ok'),
        type: 'error'
      },
      disableClose: true,
      id: 'password-modal-component',
      width: '100%',
      maxWidth: '520px'
    });
  }
}
