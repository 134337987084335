import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import {
  SetCompanyDataSection,
  SetPersonalDataSection
} from '@app/core/state/proposal-information/proposal-information.actions';
import * as proposalInformationSelect from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  ClientSignUpData,
  GetProposalInformationDTO,
  ProposalParameters,
  ProposalSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { RegexConstants } from '@shared/services/form-validation/validators/regex.constants';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators';

const BRAZIL_DDI_CODE = 55;

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit, OnDestroy, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public comboParams: ProposalParameters;
  @Input() public proposalDataRes: any;
  @Input() public setPersistedData: Observable<GetProposalInformationDTO>;
  @Output() public toStart = new EventEmitter<FormGroup>();

  public proposal: ProposalSignUpData;
  public form: FormGroup;
  public maritalStatusChange$ = null;
  public personType: string;
  public product: string;
  public title: string;
  public personsType = SubmitPersonType;
  public focusItems = {};

  public maskDocumentNumber = '';
  public maskDocumentNumberCompany = '';

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;

  public Insert = Tag.Insert;
  public Select = Tag.Selecionar;
  public event_category: string;


  get personForm() {
    return this.form.get('person')
  }

  get mainEmail() {
    return this.personForm?.get('mainEmail')
  }

  constructor(
    private internationalizationService: InternationalizationService,
    private genTagger: GenTagger,
    private store$: Store<AppState>
  ) { }

  ngOnInit() {
    this.buildForm();
    this.toStart.emit(this.form);
    this.setFormSubscription();
    this.getProposalData();
    this.valuesFormsSubscribe();
    this.focusItems = {};
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.form) {
      this.toStart.emit(this.form);
    }
  }
  private buildForm(): void {
    this.form = new FormGroup({
      isDataFromService: new FormControl(),
      clientId: new FormControl(),
      proposalId: new FormControl(),
      mainFlag: new FormControl(),
      type: new FormControl(),
      companyRelationshipTypeId: new FormControl(),
      person: new FormGroup({
        id: new FormControl(),
        type: new FormControl(),
        isDataFromService: new FormControl(),
        name: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ.0-9]{1,}[a-zA-ZÀ-ÿ.0-9]{1,}(?: [a-zA-ZÀ-ÿ0-9]+){1,}$/)
        ]),
        documentId: new FormControl(),
        documentNumber: new FormControl({ value: null, disabled: true }, Validators.required),
        mainPhone: new FormGroup({
          id: new FormControl(),
          clientId: new FormControl(),
          mainFlag: new FormControl(),
          type: new FormControl(),
          ddiNumber: new FormControl({ value: null, disabled: true }),
          dddNumber: new FormControl({ value: null, disabled: true }),
          phoneNumber: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.minLength(9)])
        })
      })
    });
  }

  private addPhysicalForm(): void {
    const personControl = this.form.get('person') as FormGroup;

    personControl.addControl(
      'physical',
      new FormGroup({
        clientId: new FormControl(),
        birthDate: new FormControl({ value: null, disabled: true }, [Validators.required, this.dateValidator]),
        nationalityId: new FormControl(null, Validators.required),
        genderId: new FormControl(null, Validators.required),
        professionId: new FormControl(null, Validators.required),
        motherName: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
        ])
      })
    );

    personControl.addControl(
      'mainEmail',
      new FormGroup({
        id: new FormControl(),
        clientId: new FormControl(),
        mainFlag: new FormControl(),
        type: new FormControl(),
        email: new FormControl(null, [
          Validators.required,
          Validators.email,
          ...this.pioneerValidators.email,
          Validators.pattern(RegexConstants.email),
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(60)
        ])
      })
    );
  }

  private addLegalForm(): void {
    (this.form.get('person') as FormGroup).addControl(
      'legal',
      new FormGroup({
        clientId: new FormControl(),
        openingDate: new FormControl({ value: null, disabled: true }, [Validators.required, this.dateValidator]),
        companyType: new FormControl(null, Validators.required),
        legalNatureId: new FormControl(null, Validators.required),
        branchGroupCode: new FormControl(null, Validators.required),
        branchId: new FormControl(null, Validators.required)
      })
    );
  }

  public valuesFormsSubscribe(): void {
    this.setPersistedData.subscribe(res => {
      if (!!res) {
        this.proposalDataRes = res;
        this.setValueRecovered();
      }
      this.toStart.emit(this.form);
    })
  }

  public getProposalData() {
    this.store$
      .select(proposalInformationSelect.getSimulationProposalData)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        take(1),
        tap(data => {
          if (data.productCode === ProductCodeEnum.CDC) {
            this.event_category = '/portallojista/criarproposta/cdc/cadastro';
          } else if (data.productCode === ProductCodeEnum.CSC) {
            this.event_category = '/portallojista/criarproposta/csc/cadastro';
          } else if (data.productCode === ProductCodeEnum.CSCP) {
            this.event_category = '/portallojista/criarproposta/cscp/cadastro';
          }

          this.form
            .get('person')
            .get('mainPhone')
            .patchValue(data.mainClient.person.phones[0]);
        })
      )
      .subscribe((data: ProposalSignUpData) => {
        this.proposal = data;
        this.personType = data.mainClient.person.type;
        this.product = data.productCode;
        const mainClient = data.mainClient;

        if (this.personType === SubmitPersonType.PF) {
          this.title = 'STEP-REGISTER-INTRODUCTION-NATURAL-PERSON';
          this.addPhysicalForm();
          mainClient.person.mainEmail = mainClient.person.emails[0];
          mainClient.person.physical.birthDate = mainClient.person.physical.birthDate
            ? mainClient.person.physical.birthDate
            : null;

          this.form.patchValue(mainClient);

          if (this.form.valid) {
            this.updatePersonalData();
          }
        }

        if (this.personType === SubmitPersonType.PJ) {
          this.title = 'STEP-REGISTER-INTRODUCTION-JURIDICAL-PERSON';
          this.addLegalForm();
          mainClient.person.legal.openingDate = mainClient.person.legal.openingDate
            ? moment(mainClient.person.legal.openingDate).format('DDMMYYYY')
            : null;
          this.form.patchValue(mainClient);

          if (this.form.valid) {
            this.updateCompanyData();
          }
        }

        this.maskDocumentNumber = this.pioneerMasks.documentNumber.maskBack;
        this.maskDocumentNumberCompany = this.pioneerMasks.documentNumber.companyMaskBack;
      });
  }

  public setValueRecovered() {
    let legalperson = this.proposalDataRes?.clients.find(J => J.person.type === 'J');
    let physicalPerson = this.proposalDataRes?.clients.find(F => F.person.type === 'F');
    if (!!physicalPerson) {
      physicalPerson.person.mainEmail = physicalPerson.person.emails[0];
      this.form.patchValue(physicalPerson);

    } if (!!legalperson) {
      this.title = 'STEP-REGISTER-INTRODUCTION-JURIDICAL-PERSON';
      this.addLegalForm();
      if (!!legalperson.person.legal?.openingDate) {
        legalperson.person.legal.openingDate = legalperson.person.legal.openingDate
          ? moment(legalperson.person.legal.openingDate).format('DDMMYYYY')
          : null;
      }
      this.form.patchValue(legalperson);
    }
    legalperson = null;
    physicalPerson = null;
  }

  private setFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(1000),
        filter(() => this.form.valid)
      )
      .subscribe(() => {
        if (this.personType === SubmitPersonType.PF) {
          this.updatePersonalData();
        }

        if (this.personType === SubmitPersonType.PJ) {
          this.updateCompanyData();
        }
      });
  }

  private updatePersonalData(): void {
    const data: ClientSignUpData = this.form.getRawValue();

    this.store$.dispatch(
      new SetPersonalDataSection({
        ...data,
        person: {
          ...data.person,
          physical: {
            ...data.person.physical,
            birthDate: data.person.physical.birthDate.includes('x')
              ? data.person.physical.birthDate
              : moment(data.person.physical.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          },
          phones: [
            {
              ...data.person.mainPhone,
              ...this.handlePhoneNumber('mainPhone')
            }
          ]
        }
      })
    );
  }

  private updateCompanyData(): void {
    const data: ClientSignUpData = this.form.getRawValue();

    this.store$.dispatch(
      new SetCompanyDataSection({
        ...data,
        person: {
          ...data.person,
          legal: {
            ...data.person.legal,
            openingDate: moment(data.person.legal.openingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          },
          phones: [
            {
              ...data.person.mainPhone,
              ...this.handlePhoneNumber('mainPhone')
            }
          ]
        }
      })
    );
  }

  public getPrefixCode() {
    const dddNumber = this.form
      .get('person')
      .get('mainPhone')
      .get('dddNumber').value;
    if (!!dddNumber) {
      return `(${this.form
        .get('person')
        .get('mainPhone')
        .get('dddNumber').value
        }) `;
    }
    return '';
  }

  public getMask() {
    const dddNumber = this.form
      .get('person')
      .get('mainPhone')
      .get('dddNumber').value;

    if (!!dddNumber) {
      return 'AAAAA-AAAA';
    }
    return '(AA) AAAAA-AAAA';
  }

  public handlePhoneNumber(controlName: string) {
    if (!this.form.get(controlName) || (!!this.form.get(controlName) && this.form.get(controlName).invalid)) {
      return;
    }
    return {
      ddiNumber: BRAZIL_DDI_CODE.toString(),
      dddNumber: this.form.get(controlName).value.phoneNumber.substr(0, 2),
      phoneNumber: this.form.get(controlName).value.phoneNumber.substr(2, 11)
    };
  }

  public dateValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      const date = moment(control.value, 'DDMMYYYY', true);
      const today = moment();
      if (date.isValid() && date.isBefore(today)) {
        return null;
      }
      return { invalidDate: true };
    }
    return null;
  }

  public checkFocus(key: string): void {
    if (!this.focusItems[key]) {
      let control: FormControl;

      if (key.includes('.')) {
        control = key.split('.').reduce((a: FormGroup, b) => a.get(b), this.form) as FormControl;
      } else {
        control = this.form.get(key) as FormControl;
      }

      control.reset();
      this.focusItems[key] = true;
    }
  }

  public onProfessionChange() {
    const value = this.form
      .get('person')
      .get('physical')
      .get('professionId').value;
    const profession = this.comboParams.professions.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - profissão`,
      event_category: this.event_category,
      event_label: profession.description.toLowerCase()
    });
  }

  public onGenreChange() {
    const value = this.form
      .get('person')
      .get('physical')
      .get('genderId').value;
    const gender = this.comboParams.genders.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - gênero`,
      event_category: this.event_category,
      event_label: gender.description.toLowerCase()
    });
  }

  public onLegalNatureChange() {
    const value = this.form
      .get('person')
      .get('legal')
      .get('legalNatureId').value;
    const legalNature = this.comboParams.legalNatures.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - natureza jurídica`,
      event_category: this.event_category,
      event_label: legalNature.description.toLowerCase()
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
